.dev__container__1 {
    grid-template-columns: repeat(2, 270px);
    justify-content: center;
    column-gap: 1.8rem;
    padding-bottom: 1.8rem;
}

.dev__container__2 {
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    column-gap: 1.8rem;
}

.dev__container__3 {
    grid-template-columns: repeat(1, 270px);
    justify-content: center;
    column-gap: 1.8rem;
    padding-top: 1.8rem;
}

.dev__content {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* background-color: var(--container-color); */
    padding: 6rem 0 2rem 2.5rem;
}


.dev__odin {
    background: url(../../assets//odin-dev.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* box-shadow: inset 0 0 25px rgb(255, 255, 255); */
    /*this box-shadow adds a blur effect */
    border-radius: 1.5rem;
    /* width: 270px;
    height: 268px; */
    /* animation: profile__animate 8s ease-in-out infinite 1s; */
}

.dev__veerav {
    background: url(../../assets//veerav-dev-2.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}

.dev__toby {
    background: url(../../assets//toby-dev.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}


.dev__peter {
    background: url(../../assets//petertechvvs.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}

.dev__isaac {
    background: url(../../assets//isaac-dev.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}

.dev__adam {
    background: url(../../assets//adam-dev.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}

.dev__joeBoat {
    background: url(../../assets//joe-boat-dev.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}

.dev__joeMiami {
    background: url(../../assets//joe-miami-dev.JPG);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}

.dev__ryan {
    background: url(../../assets//ryan-dev.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem;
}



.dev__icon {
    display: block;
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

.dev__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
    color: var(--body-color);
}

.dev__button {
    color: var(--body-color);
    font-size: 30px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
    text-shadow: 2px 2px 10px rgb(0, 0, 0);
    /* remove text shadow after implementing the additional dev images */

    padding: 0 0 8px 0;
    position: absolute;

    bottom: 0;
}

.dev__button-icon {
    font-size: 30px;
    transition: 0.3s;
}

.dev__button:hover .dev__button-icon {
    transform: translateX(3rem);
}


.dev__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.dev__modal-content {
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.dev__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.dev__modal-title,
.dev__modal-description {
    text-align: center;
}

.dev__modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.dev__modal-description {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.dev__modal-dev {
    row-gap: 0.75rem;
}

.dev__modal-service {
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.dev__modal-icon {
    color: var(--title-color);
    font-size: 1.1rem;
}

.dev__modal-info {
    font-size: var(--small-font-size);
}

/* Active Modal */
.active-modal {
    opacity: 1;
    visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .dev__container__1 {
        grid-template-columns: repeat(2, 218px);
    }

    .dev__container__2 {
        grid-template-columns: repeat(3, 218px);
    }

    .about__img {
        width: 220px;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {

    .dev__container__1,
    .dev__container__2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .dev__content {
        padding: 3.5rem 0.5rem 1.25rem 1.5rem;
    }

    .dev__icon {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .dev__modal-content {
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .dev__modal-description {
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {

    .dev__container__1,
    .dev__container__2 {
        grid-template-columns: max-content;
    }

    .dev__content {
        padding-right: 6rem;
    }
}